import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/greg/Desktop/projects/portfolio/src/components/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In my day to day work I use a lot of different open-source `}<inlineCode parentName="p">{`react`}</inlineCode>{` components
and here and there I contribute back to that community. Here are some of the
more prominent ones I've worked on, though you can see all my open source work
in my github activity feed...`}</p>
    <h2>{`react banner`}</h2>
    <p>{`One of the more complex components in the `}<a parentName="p" {...{
        "href": "./webpack"
      }}>{`webpack docs`}</a>{` site was the
banner, so we decided to abstract and open source it as an `}<inlineCode parentName="p">{`npm`}</inlineCode>{` package. We
distributed it as `}<a parentName="p" {...{
        "href": "https://github.com/skipjack/react-banner"
      }}>{`react-banner`}</a>{` and I still maintain and use it in few
projects.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eb17c370ba933715fb5e5e3e8c6df78f/644c5/react-banner-normal.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAQT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAGtx1FNGD//xAAaEAADAQADAAAAAAAAAAAAAAABAgMABBAS/9oACAEBAAEFArFZzlyJvTyuIBwRR1//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQISITEQYZH/2gAIAQEABj8Cs4ijXfRpGTEV5x//xAAaEAEAAwADAAAAAAAAAAAAAAABABFRECHx/9oACAEBAAE/IVxKMCCvvoTyoDQExiVhdOH/2gAMAwEAAgADAAAAEGDP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAAEFAQAAAAAAAAAAAAAAAQAQESExQfH/2gAIAQEAAT8QTRQNrLbsRMIhh6XjPDxy98LkHlegCU//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "react banner demo",
            "title": "react banner demo",
            "src": "/static/eb17c370ba933715fb5e5e3e8c6df78f/644c5/react-banner-normal.jpg",
            "srcSet": ["/static/eb17c370ba933715fb5e5e3e8c6df78f/158ba/react-banner-normal.jpg 360w", "/static/eb17c370ba933715fb5e5e3e8c6df78f/80e3c/react-banner-normal.jpg 720w", "/static/eb17c370ba933715fb5e5e3e8c6df78f/644c5/react-banner-normal.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The component allows some cool features common to many site headers and
banners. These include a dynamic search bar and child links...`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/754648db0598b70d9ec33e6b3309403c/644c5/react-banner-advanced.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABa8beChoH/8QAGhABAAEFAAAAAAAAAAAAAAAAAwIBERITIP/aAAgBAQABBQJo2MqNswjx/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAAUFAAAAAAAAAAAAAAAAAAECEnEDICMykf/aAAgBAQAGPwLGhLoBPppbA1Lln//EABkQAQACAwAAAAAAAAAAAAAAAAEhQQARIP/aAAgBAQABPyFUUUhi9NvDn3//2gAMAwEAAgADAAAAEFAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAQABBQEAAAAAAAAAAAAAAREAICExYcHx/9oACAEBAAE/EKikQwl356wcSLYaEfZnxWj/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "react banner advanced",
            "title": "react banner advanced",
            "src": "/static/754648db0598b70d9ec33e6b3309403c/644c5/react-banner-advanced.jpg",
            "srcSet": ["/static/754648db0598b70d9ec33e6b3309403c/158ba/react-banner-advanced.jpg 360w", "/static/754648db0598b70d9ec33e6b3309403c/80e3c/react-banner-advanced.jpg 720w", "/static/754648db0598b70d9ec33e6b3309403c/644c5/react-banner-advanced.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`react breadcrumbs`}</h2>
    <p>{`While working on `}<a parentName="p" {...{
        "href": "./crosslink"
      }}>{`CrossLink`}</a>{`, the complexity of the sitemap started to reach
the point where it became necessary to add breadcrumbs. I poked around to see
what was out there that was compatible with `}<inlineCode parentName="p">{`react-router`}</inlineCode>{` and landed on
`}<inlineCode parentName="p">{`react-breadcrumbs`}</inlineCode>{` which allowed more customization of what's shown than some
other automatic generators.`}</p>
    <p>{`All was working well, and then `}<inlineCode parentName="p">{`react-router@4`}</inlineCode>{` was released with some
significant breaking changes. These changes basically called for a ground-up
rewrite of the package, and since no one else had started it, I started talking
it through with the maintainer and a few other users. Once we had a good plan,
I submitted a `}<a parentName="p" {...{
        "href": "https://github.com/svenanders/react-breadcrumbs/pull/105"
      }}>{`pull request`}</a>{` that basically revamped the whole package to
support `}<inlineCode parentName="p">{`react-router@4`}</inlineCode>{`. Here's what it looks like in action...`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e3d7f9ddfed4d5c8e023e52286182973/644c5/react-breadcrumbs-demo.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3lgmP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABYQAAMAAAAAAAAAAAAAAAAAABEgIf/aAAgBAQABPyEVv//aAAwDAQACAAMAAAAQww//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAACAwEAAAAAAAAAAAAAAAABEQAgUTH/2gAIAQEAAT8QUshyIYIhgp//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "react breadcrumbs",
            "title": "react breadcrumbs",
            "src": "/static/e3d7f9ddfed4d5c8e023e52286182973/644c5/react-breadcrumbs-demo.jpg",
            "srcSet": ["/static/e3d7f9ddfed4d5c8e023e52286182973/158ba/react-breadcrumbs-demo.jpg 360w", "/static/e3d7f9ddfed4d5c8e023e52286182973/80e3c/react-breadcrumbs-demo.jpg 720w", "/static/e3d7f9ddfed4d5c8e023e52286182973/644c5/react-breadcrumbs-demo.jpg 1440w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      